import React, { createContext, useState, useEffect } from 'react'
import { menuQuery } from '../../queries'

export const DataContext = createContext()

export const DataProvider = ({ children }) => {
  const [menu, setMenu] = useState()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_TARGET_URL}/graphql`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({ query: menuQuery })
    })
      .then(response => response.json())
      .then(response => setMenu(response?.data?.menuByName?.level0[0]?.level1))
  }, [])

  return (
    <DataContext.Provider
      value={{
        menu
      }}
    >
      {children}
    </DataContext.Provider>
  )
}
