import React, { useContext, useEffect, useCallback } from 'react'
import { Box, Grid, Select, FormControl, InputLabel, MenuItem, Button } from '@material-ui/core'
import AuditItem from '../AuditItem'
import { Delete, Send } from '@material-ui/icons'
import { ShoppingCartContext } from '../../contexts/ShoppingCartContext'
import './CartItem.scss'

export default function CartItem ({ mapKey, item, setPopupRecipient, setPopupItem, setPopupVisibility }) {
  const { removeItem, addEmailRecipient, removeRecipient } = useContext(ShoppingCartContext)

  const [recipient, setRecipient] = React.useState(item.recipients[0].email)

  const handleChange = event => {
    setRecipient(event.target.value)
  }

  const queueRecipient = useCallback(addEmailRecipient, [])

  const handleDelete = () => {
    removeRecipient(mapKey)
    removeItem(mapKey)
  }

  const handleSend = () => {
    setPopupItem(item)
    setPopupRecipient(recipient)
    setPopupVisibility(true)

    // put send request here
    removeRecipient(mapKey)
    removeItem(mapKey)
  }

  useEffect(() => {
    queueRecipient({ email: recipient, id: mapKey })
  }, [queueRecipient, mapKey, recipient])

  return (
    <Box className='outline-box' border={1} margin={1} borderRadius={5}>
      <Grid container spacing={1}>
        <Grid item md={4} xs={12}>
          <Box className='auditItem-card'>
            <AuditItem className='auditItem-override' item={item} />
          </Box>
        </Grid>
        <Grid className='recipient-select-wrapper' item md={4} xs={12}>
          <InputLabel id='item-group-recipient-label'>Select Recipient Group</InputLabel>
          <FormControl className='formControl-wrapper'>
            <Select
              className='recipient-select'
              labelId='item-group-recipient-label'
              id='item-group-recipient'
              value={recipient}

              onChange={handleChange}
              label=''
            >
              {item.recipients.map(({ name, email }, idx) => (
                <MenuItem key={`recipient-${name}-${idx}`} value={email}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid className='button-group' item md={4} xs={12}>
          <Box className='button-group'>
            <Button
              endIcon={<Send />}
              color='primary'
              variant='contained'
              className='actionButton'
              onClick={handleSend}
            >
                            Send
            </Button>
            <Button
              endIcon={<Delete />}
              color='secondary'
              variant='contained'
              className='actionButton'
              onClick={handleDelete}
            >
                            Delete
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
