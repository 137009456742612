import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Typography, Box, Grid } from '@material-ui/core'
import { useSearch } from '../../hooks'
import queryString from 'query-string'
import AuditItem from '../AuditItem'
import microscope from '../../images/microscope.jpg'
import production from '../../images/production.jpg'

import './search.scss'
import SelectFacet from '../SearchFacets/SelectFacet'
import TextInputFacet from '../SearchFacets/TextInputFacet'
import SliderFacet from '../SearchFacets/SliderFacet'

export default function Search () {
  const maxMembers = 100
  const minMembers = 0
  const [filter, setFilter] = useSearch({
    entityType: 'all',
    sector: 'all',
    raci: 'all',
    keyword: '',
    member: '',
    size: [minMembers, maxMembers]
  })
  const history = useHistory()
  const location = useLocation()
  const url = location.search
  const params = queryString.parse(url.replace('?', ''))

  const results = [{
    id: 1,
    title: 'Filtered Site 1',
    cardImage: microscope,
    rating: 3,
    recipients: [
      {
        name: 'Audit Prioritization Team',
        email: 'carol@email.com'
      },
      {
        name: 'Oncology Strategy Lead',
        email: 'Steve@email.com'
      }
    ]
  },
  {
    id: 2,
    title: 'Filtered Site 2',
    cardImage: production,
    rating: 4,
    recipients: [
      {
        name: 'Audit Prioritization Team',
        email: 'carol@email.com'
      },
      {
        name: 'Oncology Strategy Lead',
        email: 'Steve@email.com'
      }
    ]
  }]
  const entityTypes = [
    'all',
    'group',
    'process'
  ]
  const businessSectors = [
    'all',
    'pharma',
    'consumer',
    'med devices'
  ]
  const raciTypes = [
    'all',
    'responsible',
    'accountable',
    'consulted',
    'informed'
  ]

  // only accept a query string value if it is a valid Select option
  if (entityTypes.includes(params.entityType)) {
    filter.entityType = params.entityType
  }
  const handleChangeFor = category => value => {
    const clonedFilter = JSON.parse(JSON.stringify(filter))
    clonedFilter[category] = value
    setFilter(clonedFilter)
    params[category] = value

    const returnURL = `/search?${queryString.stringify(params)}`
    history.push(returnURL)
  }

  return (
    <div className='search-wrapper'>
      <Typography variant='h4' component='h2'>
        {results ? `Showing ${results.length} results` : ''}
      </Typography>
      <div className='search-asides__flex-row'>
        <aside className='search-filters'>
          <Typography variant='h6' component='h3'>
            Filters
          </Typography>
          <div className='aside-content__padding-top'>
            <div className='search-filter'>
              <SelectFacet
                onChange={value => {
                  handleChangeFor('entityType')(value)
                }}
                selectOptions={entityTypes}
                title='Entity Type'
                defaultOverride={params.entityType}
              />
              {filter.entityType === 'group' || filter.entityType === 'all'
                ? (
                  <SelectFacet
                    onChange={value => {
                      handleChangeFor('sector')(value)
                    }}
                    title='Business Sector'
                    selectOptions={businessSectors}
                    defaultOverride={params.sector}
                  />
                )
                : null}
              {filter.entityType === 'group' || filter.entityType === 'all'
                ? (
                  <SelectFacet
                    onChange={value => {
                      handleChangeFor('raci')(value)
                    }}
                    title='RACI'
                    selectOptions={raciTypes}
                    defaultOverride={params.raci}
                  />
                )
                : null}
              <TextInputFacet
                title='Keyword Search'
                onChange={value => {
                  handleChangeFor('keyword')(value)
                }}
                defaultOverride={params.keyword}
              />
              <TextInputFacet
                title='Member Search'
                onChange={value => {
                  handleChangeFor('member')(value)
                }}
                defaultOverride={params.member}
              />
              {filter.entityType === 'group' || filter.entityType === 'all'
                ? (
                  <SliderFacet
                    title='Group Size'
                    onChange={value => {
                      handleChangeFor('size')(value)
                    }}
                    defaultOverride={params.size}
                  />
                )
                : null}
            </div>
          </div>
        </aside>
        <aside className='search-results'>
          <Typography variant='h6' component='h3'>
            Results
          </Typography>
          <div className='aside-content__padding-top'>
            <Grid container>
              {results && results.map(item => (
                <Box p={2} key={item.id}>
                  <AuditItem item={item} />
                </Box>
              ))}
            </Grid>
          </div>
        </aside>
      </div>
    </div>
  )
}
