import React, { useState } from 'react'
import Header from '../Header'

export default function HamburgerMenu () {
  const [isVisible, setVisible] = useState(false)

  return (
    <div>
      <Header setVisible={setVisible} isVisible={isVisible} />
    </div>
  )
}
