import React from 'react'
import { Container } from '@material-ui/core'
import { Sankey } from '../Graphs'

export const Processes = () => {
  const data = {
    nodes: [
      { node: 0, name: 'Risk Based Knowledge Platform' },
      { node: 1, name: 'IT Platform Engineering Activities' },
      { node: 2, name: 'Drupal Data Ingestion' },
      { node: 3, name: 'Drupal View Capabilities' },
      { node: 4, name: 'Webforms - Capturing Data in Context' },
      { node: 5, name: 'Drupal Shopping Cart Experience' },
      { node: 6, name: 'User Experience of the Site' },
      { node: 7, name: 'SDLC Documentation' },
      { node: 8, name: 'Devops' },
      { node: 9, name: 'JJAR Data' },
      { node: 10, name: 'Import CAPAs into Drupal' },
      { node: 11, name: 'Content Creation' },
      { node: 12, name: 'DB Inquiries' },
      { node: 13, name: 'Shopping Cart for Clinical Investigator Sites' },
      { node: 14, name: 'Interlocking SIPOCs and RACIs' }
    ],
    links: [
      { source: 0, target: 1, value: 2 },
      { source: 0, target: 2, value: 3 },
      { source: 0, target: 3, value: 2 },
      { source: 0, target: 4, value: 1 },
      { source: 0, target: 5, value: 1 },
      { source: 0, target: 6, value: 1 },
      { source: 1, target: 7, value: 1 },
      { source: 1, target: 8, value: 1 },
      { source: 2, target: 9, value: 1 },
      { source: 2, target: 10, value: 1 },
      { source: 3, target: 11, value: 1 },
      { source: 3, target: 12, value: 1 },
      { source: 2, target: 12, value: 1 },
      { source: 4, target: 12, value: 1 },
      { source: 5, target: 13, value: 1 },
      { source: 6, target: 14, value: 1 }
    ]
  }

  return (
    <Container>
      <Sankey data={data} />
    </Container>
  )
}
