import React from 'react'
import {
  Audits,
  CAPAs,
  ContentHome,
  ExternalServiceProvider,
  Findings,
  Inspections,
  QualityInvestigations
} from './ContentPages'
import { LOCHome } from './LOCHome'

import './contentDetails.scss'

const ContentDetails = ({ match }) => {
  const type = match.params.type
  const id = match.params.id

  const openModalForContent = id => {
    return id
  }
  const contentData = {
    mockHomeData: {
      contacts: {
        'Business Contact': {
          name: 'Adam Fermier',
          email: 'adam@example.com'
        },
        'Quality Contact': {
          name: 'Sam Tung',
          email: 'sam@example.com'
        }
      },
      location: {
        name: 'JnJ Springhouse',
        address: 'Welsh & McKean Rd, Spring House, PA 19477',
        country: 'USA'
      },
      ratingModel: 'Latent Dirichlet Allocation',
      alternativeNames: [
        'Adam'
      ],
      associatedCompanies: [
        'J&J'
      ]
    }
  }

  const TabContents = {
    home: () => (
      <ContentHome
        contacts={contentData.mockHomeData?.contacts}
        location={contentData.mockHomeData?.location}
        ratingModel={contentData.mockHomeData?.ratingModel}
        alternativeNames={contentData.mockHomeData?.alternativeNames}
      />),
    audits: () => <Audits />,
    inspections: () => <Inspections />,
    findings: () => <Findings openModalCallback={openModalForContent} />,
    qis: () => <QualityInvestigations />,
    capas: () => <CAPAs />,
    esp: () => <ExternalServiceProvider id={id} />,
    loc: () => <LOCHome id={id} />
  }

  return (
    <>
      {TabContents[type] ? TabContents[type]() : <div>Content not found for this type.</div>}
    </>
  )
}

export default ContentDetails
