import React, { useState } from 'react'
import { Input, FormControl, Typography } from '@material-ui/core'
import '../searchFacets.scss'

/**
 *
 * @param {function} onChange - Function callback for changing text input value
 * @param {string} title - String containing the name of this text input facet - displayed in <Typography> over text input element
 *
 * @return {Object} React component representing a text input element
 */
export default function TextInputFacet ({ onChange, title, defaultOverride }) {
  if (defaultOverride === undefined) {
    defaultOverride = ''
  }
  const [text, setText] = useState(defaultOverride)

  const handleChange = event => {
    onChange(event.target.value)
    setText(event.target.value)
  }

  return (
    <FormControl
      className='search-facet-wrapper'
    >
      <Typography>
        {title}
      </Typography>
      <Input
        id='standard-search'
        placeholder={title}
        type='search'
        value={text}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'description' }}
      />
    </FormControl>
  )
}
