import React, { useContext, useState } from 'react'
import { Grid, Box, Paper, Typography, Hidden, Button } from '@material-ui/core'
import { Delete, Send } from '@material-ui/icons'
import CartItem from '../CartItem'
import EmailSentMessage from '../EmailSentMessage'
import SendAllMessage from '../SendAllMessage'

import { ShoppingCartContext } from '../../contexts'
import './ShoppingCart.scss'

function ShoppingCartBodyContents ({ setPopupRecipient, setPopupItem, setPopupVisibility }) {
  const { shoppingCartItems } = useContext(ShoppingCartContext)

  return (
    <Box>
      <Grid container>
        <Hidden smDown>
          <Grid item md={4}>
            <Box className='section-header'><Typography variant='h6'>Item</Typography> </Box>
          </Grid>
          <Grid item md={4}>
            <Box className='section-header'><Typography variant='h6'>Recipient</Typography> </Box>
          </Grid>
          <Grid item md={4}>
            <Box className='section-header'><Typography variant='h6'>Send/Delete</Typography> </Box>
          </Grid>
        </Hidden>
      </Grid>
      <Grid>
        {Array.from(shoppingCartItems).map(([mapKey, value]) => (
          <CartItem
            setPopupRecipient={recipient => setPopupRecipient(recipient)}
            setPopupItem={item => setPopupItem(item)}
            setPopupVisibility={status => setPopupVisibility(status)}
            item={value}
            mapKey={mapKey}
            key={`Item=${mapKey}`}
          />
        ))}
      </Grid>
    </Box>
  )
}

function ShoppingCartNoContent () {
  return (
    <Box className='no-content'>
      <Typography variant='h5'>There doesn't seem to be anything here...</Typography>
      <Typography variant='h6'>Try Searching for Processes and clicking on the cart icon to add them to your cart.</Typography>
    </Box>
  )
}
function SendDeleteAll ({ setSendAllVisibility, setShoppingCartSize }) {
  const { clearCart, shoppingCartItems } = useContext(ShoppingCartContext)

  const HandleSendAll = () => {
    setSendAllVisibility(true)
    setShoppingCartSize(shoppingCartItems.size)
    // handle sending code here

    clearCart()
  }
  const HandleDeleteAll = () => {
    clearCart()
  }

  return (
    <Box className='floating-btn-group'>
      <Paper>
        <Button
          endIcon={<Send />}
          color='primary'
          variant='contained'
          className='actionButton'
          onClick={HandleSendAll}
        >
          Send All
        </Button>
        <Button
          endIcon={<Delete />}
          color='secondary'
          variant='contained'
          className='actionButton'
          onClick={HandleDeleteAll}
        >
          Delete All
        </Button>
      </Paper>
    </Box>
  )
}

export default function ShoppingCart () {
  const { shoppingCartItems } = useContext(ShoppingCartContext)

  // States are updated in child components by passing down functions to set alter their values
  const [popupRecipient, setPopupRecipient] = useState(null)
  const [popupItem, setPopupItem] = useState(null)
  const [popupVisibility, setPopupVisibility] = useState(false)
  const [sendAllVisibility, setSendAllVisibility] = useState(false)
  const [shoppingCartSize, setShoppingCartSize] = useState(0)
  return (
    <Paper className='cart-background'>
      <EmailSentMessage
        popupRecipient={popupRecipient}
        popupItem={popupItem}
        isVisible={popupVisibility}
        setPopupItem={item => setPopupItem(item)}
        setPopupRecipient={recipient => setPopupItem(recipient)}
        setVisibility={status => setPopupVisibility(status)}
      />
      <SendAllMessage
        setVisibility={status => setSendAllVisibility(status)}
        isVisible={sendAllVisibility}
        shoppingCartSize={shoppingCartSize}
        setShoppingCartSize={size => setShoppingCartSize(size)}
      />
      <Typography variant='h4' className='cart-title'>Your Cart</Typography>
      {shoppingCartItems && shoppingCartItems.size > 0
        ? (
          <ShoppingCartBodyContents
            setPopupRecipient={recipient => setPopupRecipient(recipient)}
            setPopupItem={item => setPopupItem(item)}
            setPopupVisibility={status => setPopupVisibility(status)}
          />
        )
        : <ShoppingCartNoContent />}
      {shoppingCartItems && shoppingCartItems.size > 0
        ? (
          <SendDeleteAll
            setSendAllVisibility={status => setSendAllVisibility(status)}
            setShoppingCartSize={size => setShoppingCartSize(size)}
          />
        )
        : null}
    </Paper>
  )
}
