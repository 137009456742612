import React, { useEffect, useState } from 'react'
import { Modal, Typography } from '@material-ui/core'
import { ArrowLeft, ArrowRight, Close } from '@material-ui/icons'
import './ResultModal.scss'

export default function ResultModal ({ isOpen, onClose, nid, naturalKey, title, pages }) {
  const [currentBody, setCurrentBody] = useState(pages[0])
  const [resultIdx, setResultIdx] = useState(0)
  const resetAndClose = () => {
    setCurrentBody(pages[0])
    setResultIdx(0)
    onClose()
  }

  useEffect(() => {
    setCurrentBody(pages[resultIdx])
  }, [resultIdx, pages])

  const populateFields = (fields, i) => {
    return fields.map((item) =>
      <Typography variant='h6' className='current-fields' key={item}>
        {item}
      </Typography>
    )
  }
  return (
    <Modal
      open={isOpen}
      onClose={resetAndClose}
      className='result-modal'
    >
      <div className='result-modal-container__center-contents'>
        <ArrowLeft
          className={`result-navigation-arrow result-navigation-arrow__resize ${resultIdx <= 0 ? 'hide_arrow' : ''}`}
          onClick={() => { resultIdx > 0 && setResultIdx(resultIdx - 1) }}
        />
        <div className='result-modal-contents'>
          <div className='result-modal-header'>
            <div className='title'>
              <Typography variant='h4'>
                {title}
              </Typography>
              <Close className='modal-close-button' onClick={resetAndClose} />
            </div>
            <div className='id-info'>
              <Typography variant='h6'>
                ID: {nid}
              </Typography>
              <Typography variant='h6'>
                Natural Key: {naturalKey}
              </Typography>
            </div>
          </div>
          <div className='result-modal--main-content'>
            <Typography variant='h5' className='current-topic'>
              {currentBody.topic}
            </Typography>
            {populateFields(currentBody.fields)}
          </div>
        </div>
        <ArrowRight
          className={`result-navigation-arrow result-navigation-arrow__resize ${resultIdx >= pages.length - 1 ? 'hide_arrow' : ''}`}
          onClick={() => {
            resultIdx < pages.length - 1 && setResultIdx(resultIdx + 1)
          }}
        />
      </div>
    </Modal>
  )
}
