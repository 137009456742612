export const findingsQuery = () => (`
{
    nodeQuery (
      filter: {
        conjunction: AND,
        conditions: [
          {field: "type", value: ["findings"]}
        ]
      },
      sort: [
        {field: "changed", direction: DESC}
      ]
    ) {
      count
      entities {
        ...Defaults
        ...Findings
      }
    }
  }
  
  fragment TaxonomyTerm on TaxonomyTerm {
    tid: entityId
    uuid: entityUuid
    title: entityLabel
    url: entityUrl {
      path
    }
  }
  
  fragment Defaults on Node {
    nid: entityId
    uuid: entityUuid
    title: entityLabel
    changed: entityChanged
    created: entityCreated
    content_type: entityBundle
    published: entityPublished
    url: entityUrl {
      path
    }
  }
  
  fragment Findings on NodeFindings {
    naturalKey: fieldNaturalKey
    findingClassification: fieldFindingClassification {
      entity {
        ...TaxonomyTerm
      }
    }
    findingComplianceTopic: fieldFindingComplianceTopic {
      entity {
        ...TaxonomyTerm
      }
    }
    findingComplainceSubTopic: fieldFindingComplianceSubtopi{
      entity {
        ...TaxonomyTerm
      }
    }
    findingDetails: fieldFindingDetails {
      value
    }
    findingDepartment: fieldFindingDepartment {
      entity {
        ...TaxonomyTerm
      }
    }
    findingSubdepartment: fieldFindingSubdepartment {
      entity {
        ...TaxonomyTerm
      }
    }
    findingDescription: fieldFindingDescription {
      value
    }
    findingTitle: fieldFindingTitle
    findingType: fieldFindingType {
      entity {
        ...TaxonomyTerm
      }
    }
    findingQualityProcessGroup: fieldFindingQualityProcessGr {
      entity {
        ...TaxonomyTerm
      }
    }
    findingQualityProcess : fieldFindingQualityProcess {
      entity {
        ...TaxonomyTerm
      }
    }
    findingClinE2ECat : fieldFindingClinE2eCoreCat {
      entity {
        ...TaxonomyTerm
      }
    }
    findingSourceSystem : fieldSourceSystem {
      entity {
        ...TaxonomyTerm
      }
    }
    findingAuditID: fieldAuditId {
      entity{
        ...Defaults
      }
    }
  }  
`)
