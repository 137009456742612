import React, { useContext } from 'react'
import { ShoppingCartContext } from '../../contexts'
import { Fab } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import { Link } from 'react-router-dom'

import './continueToCart.scss'

export default function ContinueToCart () {
  const { shoppingCartItems } = useContext(ShoppingCartContext)

  return shoppingCartItems.size > 0 ? (
    <Link to='/shopping-cart'>
      <Fab className='floating-action-button' variant='extended'>
        Continue to cart with {`${shoppingCartItems.size} item${shoppingCartItems.size > 1 ? 's' : ''}`}
        <ArrowForward />
      </Fab>
    </Link>
  ) : null
}
