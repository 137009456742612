import React, { useState, useEffect } from 'react'
import { Card, Grid, Typography } from '@material-ui/core'
import SipocModal from '../SipocModal'
import { constants } from '../../lib'
import DetailsTemplate from '../DetailsTemplate'
import './groupDetails.scss'
import { AccountCircle } from '@material-ui/icons'

const GroupDetails = () => {
  const [currentModalContents, setCurrentModalContents] = useState(constants.sipoc.suppliers)
  const [shouldShowModal, setShouldShowModal] = useState(false)
  const [groupData, setGroupData] = useState({})

  const openModalForContent = id => {
    setCurrentModalContents(id)
    setShouldShowModal(true)
  }

  useEffect(() => {
    const loadGroupData = () => {
      setGroupData(data => ({
        ...data,
        name: 'Quality Analytics',
        responsible: ['Fuqu Wang', 'Eleri Price-Stockland', 'Hilde Van Den Eynde', 'Jerome Calmejane'],
        accountable: ['Paul Houri'],
        consulted: ['Federico Feldstein'],
        informed: ['Adam Fermier', 'Carol Montandon']
      }))
    }

    loadGroupData()
  }, [])

  const Modal = () => (
    <SipocModal isOpen={shouldShowModal} onClose={() => setShouldShowModal(false)} sipocIndex={currentModalContents} onNavigate={newIndex => setCurrentModalContents(newIndex)} />
  )

  const tabContents = {
    Home: () => (
      <div>
        Home
      </div>
    ),
    Members: () => (
      <div className='members-wrapper__padding'>
        <Typography variant='h5' className='raci-category__center'>
          Responsible
        </Typography>
        <div className='raci-row'>
          {groupData.responsible.map(name => (
            <div key={name}>
              <AccountCircle />
              <Typography>
                {name}
              </Typography>
            </div>
          ))}
        </div>
        <Typography variant='h5' className='raci-category__center'>
          Accountable
        </Typography>
        <div className='raci-row'>
          {groupData.accountable.map(name => (
            <div key={name}>
              <AccountCircle />
              <Typography>
                {name}
              </Typography>
            </div>
          ))}
        </div>
        <Typography variant='h5' className='raci-category__center'>
          Consulted
        </Typography>
        <div className='raci-row'>
          {groupData.consulted.map(name => (
            <div key={name}>
              <AccountCircle />
              <Typography>
                {name}
              </Typography>
            </div>
          ))}
        </div>
        <Typography variant='h5' className='raci-category__center'>
          Informed
        </Typography>
        <div className='raci-row'>
          {groupData.informed.map(name => (
            <div key={name}>
              <AccountCircle />
              <Typography>
                {name}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    ),
    Content: () => (
      <div>
        Content
      </div>
    ),
    Capabilities: () => (
      <Grid container spacing={1} className='sipoc-card-wrapper'>
        <Grid container item xs={2}>
          <Card elevation={4} className='sipoc-card' onClick={() => openModalForContent(constants.sipoc.suppliers)}>
            Supplier
          </Card>
        </Grid>
        <Grid container item xs={2}>
          <Card elevation={4} className='sipoc-card' onClick={() => openModalForContent(constants.sipoc.inputs)}>
            Input
          </Card>
        </Grid>
        <Grid container item xs={2}>
          <Card elevation={4} className='sipoc-card' onClick={() => openModalForContent(constants.sipoc.processes)}>
            Process
          </Card>
        </Grid>
        <Grid container item xs={2}>
          <Card elevation={4} className='sipoc-card' onClick={() => openModalForContent(constants.sipoc.outputs)}>
            Output
          </Card>
        </Grid>
        <Grid container item xs={2}>
          <Card elevation={4} className='sipoc-card' onClick={() => openModalForContent(constants.sipoc.customers)}>
            Customer
          </Card>
        </Grid>
      </Grid>
    )
  }

  return (
    <DetailsTemplate contentData={groupData} ModalForContent={Modal} tabContents={tabContents} />
  )
}

export default GroupDetails
