import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'

import PropTypes from 'prop-types'

import { KeyboardBackspace, Mail } from '@material-ui/icons'
import { locQuery } from '../../../queries/locQuery'
import { useHistory } from 'react-router-dom'
import StarRating from '../../StarRating'

export const LOCHome = ({ id }) => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [hasLoadingError, setHasLoadingError] = useState(false)
  const history = useHistory()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_TARGET_URL}/graphql`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({ query: locQuery({ id }) })
    })
      .then(response => response.json())
      .then(response => setData(response))
      .catch(() => setHasLoadingError(true))
      .finally(() => setIsLoading(false))
  }, [id])

  if (hasLoadingError) {
    return (
      <div className='content-home'>
        <div className='header-wrapper'>
          <div className='back-button__clickable' onClick={() => { history.goBack() }}>
            <KeyboardBackspace /> <Typography>Back</Typography>
          </div>
        </div>
        No data was found for this LOC.
      </div>
    )
  }

  return !isLoading && data && (
    <div className='content-home'>
      <div>
        <img className='header--image' alt='' />
      </div>
      <div className='content-company-name'>
        <Typography variant='h4'>
          {data?.companyTitle?.title}
          {data?.starRating?.value && <StarRating rating={Math.floor(data?.starRating?.value / 2)}/>}
        </Typography>
      </div>
      <div className='content-home-wrapper'>
        <div>
          <div className='content-home-info__space-apart'>
            <Typography variant='h5'>
              Contacts
            </Typography>
            <Typography>
              Business Contact
            </Typography>
            <Typography>
              {data?.businessContact?.url?.path
                ? (
                  <a href={`mailto:${data?.businessContact?.url?.path}`} className='content-mailto-link'>
                    {data?.businessContact?.title} <Mail className='mail-icon__shift-left' />
                  </a>
                )
                : (
                  <>
                    {data?.businessContact?.title} <Mail className='mail-icon__shift-left' />
                  </>
                )}
            </Typography>
            <Typography>
              Quality Contact
            </Typography>
            <Typography>
              {data?.qualityContact?.url?.path
                ? (
                  <a href={`mailto:${data?.qualityContact?.url?.path}`} className='content-mailto-link'>
                    {data?.qualityContact?.title} <Mail className='mail-icon__shift-left' />
                  </a>
                )
                : (
                  <>
                    {data?.qualityContact?.title} <Mail className='mail-icon__shift-left' />
                  </>
                )}
            </Typography>
          </div>
          <div className='content-home-info__space-apart'>
            <Typography variant='h5'>
              Location
            </Typography>
            <Typography>
              {data?.region?.title}
            </Typography>
            <Typography>
              {data?.region?.address1}
            </Typography>
            <Typography>
              {data?.region?.address2}
            </Typography>
            <Typography>
              {data?.countries?.join(', ')}
            </Typography>
          </div>
          <div className='content-home-info__space-apart'>
            <Typography variant='h5'>
              Star Rating Model
            </Typography>
            <Typography>
              {data?.starRatingModel?.title}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

LOCHome.propTypes = {
  id: PropTypes.string.isRequired
}
