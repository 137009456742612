import { Typography } from '@material-ui/core'
import { KeyboardBackspace } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { qualityInvestigationQuery } from '../../../queries'
import './qualityInvestigations.scss'
import SelectFacet from '../../SearchFacets/SelectFacet'
import ResultPreview from './Results/ResultPreview/ResultPreview'
import SideNavGroups from './SideNavigation/SideNavGroups/SideNavGroups'
import SideNavContentTypes from './SideNavigation/SideNavContentTypes/SideNavContentTypes'
import { useHistory } from 'react-router-dom'
import { ContentSearchBar } from './ContentSearchBar/ContentSearchBar'
import { constants } from '../../../lib/constants'
import ResultModal from './Results/ResultModal/ResultModal'

export const QualityInvestigations = () => {
  const auditID = 1
  const findingID = 1
  const CAPAID = 1
  const [filter, setFilter] = useState({
    group: constants.filter.all,
    text: ''
  })
  const history = useHistory()
  const [dataToDisplay, setDataToDisplay] = useState()
  const [allData, setAllData] = useState()
  const [shouldShowModal, setShouldShowModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [hasLoadingError, setHasLoadingError] = useState(false)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_TARGET_URL}/graphql`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({ query: qualityInvestigationQuery() })
    })
      .then(response => response.json())
      .then(response => {
        setAllData(response?.data?.nodeQuery?.entities)
        setDataToDisplay(response?.data?.nodeQuery?.entities)
      })
      .catch(() => setHasLoadingError(true))
      .finally(() => setIsLoading(false))

    // Filter for groups.
    switch (filter.group) {
      case constants.filter.all:
        setDataToDisplay(allData)
        break
      case constants.filter.none:
        setDataToDisplay(allData.filter(item => !item.group))
        break
      default:
        setDataToDisplay(allData.filter(item => item.group === filter.group))
        break
    }
    // Filter for text.
    if (filter.text.length > 0) {
      setDataToDisplay(data =>
        (data.filter(item =>
          JSON.stringify(item).toLowerCase().indexOf(filter.text.toLowerCase()) > -1)))
    }
  }, [allData, filter])

  const filterType1 = [
    constants.filter.all,
    constants.filter.none,
    ...(new Set(allData?.reduce((acc, entity) => {
      if (entity.group) {
        acc.push(entity.group)
      }
      return acc
    }, [])))
  ]

  const filterData = category => value => {
    setFilter(data => ({
      ...data,
      [category]: value
    }))
  }

  const createPages = (item) => {
    const pages = [
      {
        topic: 'Base Information',
        fields: [
          `Group: ${item?.group}`,
          `Finding ID: ${item.findingID?.entity.nid}`,
          `Processes: ${item.qiProcess?.map((i) => { return ` ${i.entity.title}` })}`,
          `Subprocesses: ${item.qiSubProcess?.map((i) => { return ` ${i.entity.title}` })}`,
          `Root Cause(s): ${item.qiRootCauses?.entity.title}`,
          `Therapeutic Area ${item.qiTherapeuticArea?.entity.title}`,
          `Level: ${item.qiLevel?.entity.title}`,
          `Project: ${item.qiProject?.entity.title}`,
          `Approved By: ${item.qiApprovedBy?.entity.title}`,
          `Sources: ${item.qiSources?.entity.title}`,
          `Overall Effectiveness: ${item.qiOverallEffectiveness?.entity.title}`
        ]
      },
      {
        topic: 'Base Information',
        fields: [
          `Functional Area 1: ${item.qiFunctionalArea1?.entity.title}`,
          `Functional Area 2: ${item.qiFunctionalArea2?.entity.title}`,
          `GXP: ${item.qiGxp?.map((i) => { return ` ${i.entity.title}` })}`,
          `Originator: ${item.qiOriginator?.entity.title}`,
          `RC Method: ${item.qiRcMethod?.map((i) => { return ` ${i.entity.title}` })}`,
          `Product or Process: ${item.qiProductOrProcess?.entity.title}`,
          `Segment: ${item.qiSegment?.title}`,
          `All Segments: ${item.qiAllSegmentsAffected?.entity.title}`
        ]
      },
      {
        topic: 'Date Information',
        fields: [
          `Creation Date: ${item.qiCreationDate?.value}`,
          `Approval Date ${item.qiApprovalDate?.value}`,
          `Date Occured: ${item.qiDateOccurred?.value}`,
          `Date Complete: ${item.qiDateComplete?.value}`,
          `Due Date: ${item.qiDueDate?.value}`,
          `Original Due Date: ${item.qiOriginalDueDate?.value}`
        ]
      },
      {
        topic: 'Impact Information',
        fields: [
          `Assessment Approval Date: ${item.qiImpactAssessmentApproval?.value}`,
          `Category: ${item.qiImpactCategory?.entity.entityLabel}`,
          `Classification: ${item.qiImpactClassification?.entity.title}`,
          `Score: ${item.qiImpactScore?.entity.title}`,
          `Description: ${item.qiImpactDescription?.entity.title}`
        ]
      },
      {
        topic: 'People Involved',
        fields: [
          `Owner: ${item.qiOwner?.entity.title}`,
          `Quality Leader: ${item.qiQualityLeader?.entity.title}`,
          `Issue Reported By: ${item.qiIssueReportedBy?.entity.title}`
        ]
      },
      {
        topic: 'Business Information',
        fields: [
          `Business Unit: ${item.qiBusinessUnit?.entity.title}`,
          `Owning Franchise: ${item.qiOwningFranchise?.entity.title}`,
          `Owning Site Region: ${item.qiOwningSiteRegion?.entity.title}`,
          `Recommended Inventory Level: ${item.qiRecommendedInvLevel?.entity.title}`
        ]
      },
      {
        topic: 'Record Information',
        fields: [
          `Record State: ${item.qiRecordState?.entity.title}`,
          `Recurring Issue Related Records: ${item.qiRecurringIssueRelated?.map((i) => { return ` ${i.entity.title}` })}`,
          `Related System Records: ${item.qiRelatedSystemRecords?.map((i) => { return ` ${i.entity.title}` })}`
        ]
      }
    ]
    return pages
  }

  const createPreviews = (data) => {
    return data && data.map((item, i) =>
      <ResultPreview
        key={i}
        title={item.title}
        nid={item.nid}
        naturalKey={item.naturalKey}
        group={item.group}
        approvedBy={item.qiApprovedBy?.entity.title}
        openModalCallback={() => {
          setSelectedItem(item)
          setShouldShowModal(true)
        }}
      />)
  }

  if (hasLoadingError) {
    return (
      <div className='quality-investigations-wrapper'>
        <div className='header-wrapper'>
          <div className='back-button__clickable' onClick={() => { history.goBack() }}>
            <KeyboardBackspace /> <Typography>Back</Typography>
          </div>
        </div>
        No data was found for this Quality Investigation.
      </div>
    )
  }

  return !isLoading && allData && (
    <div className='quality-investigations-wrapper'>
      <div className='header-wrapper'>
        <div className='back-button__clickable' onClick={() => { history.goBack() }}>
          <KeyboardBackspace /> <Typography>Back</Typography>
        </div>
        <div className='content-title'>
          <Typography variant='h3'>
            Quality Investigations
          </Typography>
        </div>
      </div>
      <div className='search-bar-wrapper'>
        <ContentSearchBar
          setFilter={value => setFilter(data => ({
            ...data,
            text: value
          }))}
        />
      </div>
      <div className='result-wrapper'>
        <div className='filter-wrapper--left-column'>
          <SelectFacet
            onChange={(value) => {
              filterData('group')(value)
            }}
            selectOptions={filterType1}
            title='Group'
          />
        </div>
        <div className='table-wrapper'>
          {createPreviews(dataToDisplay)}
        </div>
        <div className='sidebar-wrapper--right-column'>
          <SideNavContentTypes
            contentType='Audits'
            number={1}
            link={`/content/audits/${auditID}`}
          />
          <SideNavContentTypes
            contentType='Findings'
            number={5}
            link={`/content/qis/${findingID}`}
          />
          <SideNavContentTypes
            contentType='CAPAs'
            number={5}
            link={`/content/capas/${CAPAID}`}
          />
          <SideNavGroups />
        </div>
      </div>
      {Object.keys(selectedItem).length > 0 &&
        <ResultModal
          isOpen={shouldShowModal}
          onClose={() => setShouldShowModal(false)}
          nid={selectedItem.nid}
          naturalKey={selectedItem.naturalKey}
          title={selectedItem.title}
          pages={createPages(selectedItem)}
        />}
    </div>
  )
}
