export const qualityInvestigationQuery = () => (`
{
  nodeQuery (
    filter: {
      conjunction: AND,
      conditions: [
        {field: "type", value: ["quality_investigations"]}
      ]
    },
    sort: [
      {field: "changed", direction: DESC}
    ]
  ) {
    count
    entities {
      ...Defaults
      ...QualityInvestigations
    }
  }
}

fragment TaxonomyTerm on TaxonomyTerm {
  tid: entityId
  uuid: entityUuid
  title: entityLabel
  url: entityUrl {
    path
  }
}

fragment QiRelatedSystemRecords on NodeQualityInvestigations {
  nid: entityId
  uuid: entityUuid
  title: entityLabel
  url: entityUrl {
    path
  }
}

fragment QiRecurringIssueRelatedRecords on NodeQualityInvestigations {
  nid: entityId
  uuid: entityUuid
  title: entityLabel
  url: entityUrl {
    path
  }
}

fragment Defaults on Node {
  nid: entityId
  uuid: entityUuid
  title: entityLabel
  changed: entityChanged
  created: entityCreated
  content_type: entityBundle
  published: entityPublished
  url: entityUrl {
    path
  }
}

fragment Findings on NodeFindings {
  nid: entityId
  uuid: entityUuid
  title: entityLabel
  url: entityUrl {
    path
  }
}

fragment QualityInvestigations on NodeQualityInvestigations {
  group: fieldGroup
  qiGxp: fieldQiGxp {
    entity {
      ...TaxonomyTerm
    }
  }
  qiLevel:fieldQiLevel {
    entity {
      ...TaxonomyTerm
    }
  }
  qiOwner: fieldQiOwner {
    entity {
      ...TaxonomyTerm
    }
  }
  qiTitle: fieldQiTitle
  finding_id: fieldFindingId {
    entity {
      ...Findings
    }
  }
  qiDueDate: fieldQiDueDate {
    value
    date
  }
  qiProcess: fieldQiProcess {
    entity {
      ...TaxonomyTerm
    }
  }
  qiProject: fieldQiProject {
    entity {
      ...TaxonomyTerm
    }
  }
  qiSegment: fieldQiSegment {
    entity {
      ...TaxonomyTerm
    }
  }
  qiSources:fieldQiSourceS {
    entity {
      ...TaxonomyTerm
    }
  }
  naturalKey: fieldNaturalKey
  qiRcMethod: fieldQiRcMethod {
    entity {
      ...TaxonomyTerm
    }
  }
  qiApprovedBy: fieldQiApprovedBy {
    entity {
      ...TaxonomyTerm
    }
  }
  qiOriginator: fieldQiOriginator {
    entity {
      ...TaxonomyTerm
    }
  }
  qiRootCauses: fieldQiRootCauseS {
    entity {
      ...TaxonomyTerm
    }
  }
  qiSubProcess: fieldQiSubProcess {
    entity {
      ...TaxonomyTerm
    }
  }
  qiImpactScore:fieldQiImpactScore {
    entity {
      ...TaxonomyTerm
    }
  }
  qiRecordState: fieldQiRecordState {
    entity {
      ...TaxonomyTerm
    }
  }
  qiApprovalDate: fieldQiApprovalDate {
    value
    date
  }
  qiBusinessUnit: fieldQiBusinessUnit {
    entity {
      ...TaxonomyTerm
    }
  }
  qiCreationDate: fieldQiCreationDate {
    value
    date
  }
  qiDateComplete: fieldQiDateComplete {
    value
    date
  }
  qiDateOccurred: fieldQiDateOccurred {
    value
    date
  }
  qiQualityLeader: fieldQiQualityLeader {
    entity {
      ...TaxonomyTerm
    }
  }
  qiImpactCategory: fieldQiImpactCategory {
    targetId
    entity {
      entityLabel
      entityUuid
    }
  }
  qiFunctionalArea1: fieldQiFunctionalArea1 {
    entity {
      ...TaxonomyTerm
    }
  }
  qiFunctionalArea2: fieldQiFunctionalArea2 {
    entity {
      ...TaxonomyTerm
    }
  }
  qiIssueReportedBy: fieldQiIssueReportedBy {
    entity {
      ...TaxonomyTerm
    }
  }
  qiOriginalDueDate: fieldQiOriginalDueDate {
    value
    date
  }
  qiOwningFranchise: fieldQiOwningFranchise {
    entity {
      ...TaxonomyTerm
    }
  }
  qiTherapeuticArea: fieldQiTherapeuticArea {
    entity {
      ...TaxonomyTerm
    }
  }
  qiAddApproval1Date: fieldQiAddApproval1Date {
    value
    date
  }
  qiAddApproval2Date: fieldQiAddApproval2Date {
    value
    date
  }
  qiAddSitesAffected: fieldQiAddSiteSAffected {
    entity {
      ...TaxonomyTerm
    }
  }
  qiAssignableCauses: fieldQiAssignableCauseS {
    entity {
      ...TaxonomyTerm
    }
  }
  qiOwningSiteRegion: fieldQiOwningSiteRegion {
    entity {
      ...TaxonomyTerm
    }
  }
  qiProductOrProcess: fieldQiProductOrProcess {
    entity {
      ...TaxonomyTerm
    }
  }
  newGroupAssociation: fieldNewGroupAssociation
  qiImpactDescription: fieldQiImpactDescription {
    entity {
      ...TaxonomyTerm
    }
  }
  qiAllSegmentsAffected: fieldQiAllSegmentSAffected {
    entity {
      ...TaxonomyTerm
    }
  }
  qiRecommendedInvLevel: fieldQiRecommendedInvLevel {
    entity {
      ...TaxonomyTerm
    }
  }
  qiImpactClassification: fieldQiImpactClassification {
    entity {
      ...TaxonomyTerm
    }
  }
  qiOverallEffectiveness: fieldQiOverallEffectiveness {
    entity {
      ...TaxonomyTerm
    }
  }
  qiRelatedSystemRecords: fieldQiRelatedSystemRecordS {
    entity {
      ...QiRelatedSystemRecords
    }
  }
  qiImpactAssessmentApproval: fieldQiImpactAssessmentAppro {
    value
    date
  }
  qiRecurringIssueRelated: fieldQiRecurringIssueRelated {
    entity {
      ...QiRecurringIssueRelatedRecords
    }
  }
}
`)
