import React from 'react'
import { Star, StarBorder } from '@material-ui/icons'

export default function StarRating ({ rating }) {
  // configurable param for max number of stars possible in a rating
  const MAX_STARS = 5

  const starsArray = Array(MAX_STARS)
    .fill(Star, 0, rating)
    .fill(StarBorder, rating)

  return (
    <div>
      {starsArray.map((StarItem, idx) => <StarItem key={`star-rating-${idx}`} />)}
    </div>
  )
}
