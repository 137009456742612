import React from 'react'
import { Typography, makeStyles, Grid, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 35,
    fontFamily: 'VerdanaPro, Arial'
  },
  navLink: {
    padding: theme.spacing(0.4),
    textAlign: 'center',
    color: '#4b82be',
    fontSize: 18,
    textDecoration: 'none'
  },
  navColumnHeader: {
    color: '#284782',
    fontSize: 22,
    marginTop: 35
  },
  navLinkHeaderNoDecoration: {
    textDecoration: 'none'
  }
}))

export default function LinkColumn ({ items }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {items && items.map(([{ category, categoryLink, links }]) => (
          <Grid item sm={4} xs={12} key={`${category}`}>
            <Link className={classes.navLinkHeaderNoDecoration} to={categoryLink} target='_blank'>
              <Typography className={classes.navColumnHeader}>
                <span>{category}</span>
              </Typography>
            </Link>
            {links && links.map(({ text, link }, count) => (
              <Box key={`${category}-${count}`}>
                <Link className={classes.navLink} to={link} target='_blank'>
                  {text}
                </Link>
              </Box>
            ))}
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
