import React from 'react'
import SelectFacet from '../../../../SearchFacets/SelectFacet'
import queryString from 'query-string'
import { useSearch } from '../../../../../hooks'
import { useHistory, useLocation } from 'react-router-dom'
import './sideNavGroups.scss'

export default function SideNavGroups () {
  const [filter, setFilter] = useSearch({
    CRSACGroups: '1',
    ESPGroups: '2',
    OCGroups: '3'
  })
  const CRSACGroups = [
    '1',
    '2',
    '3'
  ]
  const ESPGroups = [
    '4',
    '5',
    '6'
  ]
  const OCGroups = [
    '7',
    '8',
    '9'
  ]
  const history = useHistory()
  const location = useLocation()
  const url = location.search
  const params = queryString.parse(url.replace('?', ''))
  const handleChangeFor = category => value => {
    const clonedFilter = JSON.parse(JSON.stringify(filter))
    clonedFilter[category] = value
    setFilter(clonedFilter)
    params[category] = value

    const returnURL = '#'
    history.push(returnURL)
  }
  return (
    <div className='groups__shift-down'>
      <SelectFacet
        onChange={(value) => {
          handleChangeFor('CRSACGroups')(value)
        }}
        selectOptions={CRSACGroups}
        title='CRSACs'
        defaultOverride={params.CRSACGroups}
      />
      <SelectFacet
        onChange={(value) => {
          handleChangeFor('ESPGroups')(value)
        }}
        selectOptions={ESPGroups}
        title='ESPs'
        defaultOverride={params.ESPGroups}
      />
      <SelectFacet
        onChange={(value) => {
          handleChangeFor('OCGroups')(value)
        }}
        selectOptions={OCGroups}
        title='OCs'
        defaultOverride={params.OCGroups}
      />
    </div>
  )
}
