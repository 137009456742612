import React from 'react'
import { Typography, Button } from '@material-ui/core'
import './ResultPreview.scss'
import itemImage from '../../../../../images/green.jpg'

export default function ResultPreview ({ title, nid, group, approvedBy, openModalCallback }) {
  return (
    <div className='result-item-wrapper'>
      <div className='button-wrapper'>
        <Button
          className='result-button'
          onClick={() => { openModalCallback() }}
        >
          <img className='result-image' src={itemImage} alt={title} />
        </Button>
      </div>
      <div className='field-wrapper'>
        <div className='title-row'>
          <Typography variant='h5'>
            {title}
          </Typography>
          <Typography variant='h5'>
            {group}
          </Typography>
        </div>
        <div className='details-row'>
          <Typography variant='h6'>
            {nid}
          </Typography>
          <Typography variant='h6'>
            Approved By: {approvedBy || 'N/A'}
          </Typography>
        </div>
      </div>
    </div>
  )
}
