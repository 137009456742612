export const locQuery = locId => (`
  query {
    nodeById(id: "${locId}") {
      ...on NodeLocalOperatingCompany {
        id: nid,
        address1: fieldAddress1,
        address2: fieldAddress2,
        companyTitle: fieldLocTitle,
        starRating: fieldStarRating,
        starRatingModel: fieldStarRatingModel {
          entity {
            ...Node
          }
        },
        businessContact: fieldBusinessLead {
          entity {
            ...TaxonomyTerm
          }
        },
        qualityContact: fieldQualityLead {
          entity {
            ...TaxonomyTerm
          }
        },
        region: fieldRegion {
          entity {
            ...TaxonomyTerm
          }
        },
        countries: fieldCountry {
          entity {
            ...TaxonomyTerm
          }
        },
        clusters: fieldPharmaBqJjrcClusters {
          entity {
            ...TaxonomyTerm
          }
        },
        caseNetwork: fieldCaseManagementCollaborat {
          entity {
            ...TaxonomyTerm
          }
        }
      }
    }
  }

  fragment Node on Node {
    nid: entityId
    uuid: entityUuid
    title: entityLabel
    url: entityUrl {
      path
    }
  }

  fragment TaxonomyTerm on TaxonomyTerm {
    tid: tid
    uuid: entityUuid
    title: entityLabel
  }
`)
