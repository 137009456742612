import React from 'react'
import { Button, Typography, Container } from '@material-ui/core'
import distribution from '../../images/distribution.jpg'

import './suppliers.scss'

export const Suppliers = () => {
  return (
    <Container>
      <div className='group-supplier-container'>
        <div className='group-supplier--col'>
          <p>Country</p>
          <select>
            <option>Country 1</option>
            <option>Country 2</option>
          </select>
          <p>Number of Processes</p>
          <select>
            <option>0-10</option>
            <option>11+</option>
          </select>
        </div>
        <div className='group-supplier--col'>
          <Typography>Search Suppliers</Typography>
          <input type='text' />
          <Button>Search</Button>
          <SupplierRow />
          <SupplierRow />
          <SupplierRow />
        </div>
      </div>

    </Container>
  )
}

const SupplierRow = () => {
  return (
    <div className='group-supplier--row'>
      <div><img src={distribution} alt='distribution' className='supplier-profile-image__resize' /></div>
      <div className='group-supplier--row-details'>
        <Typography variant='h6'>Supplier 1</Typography>
        <Typography>From: City, County, State, Country</Typography>
        <Typography>Processes:</Typography>
      </div>
    </div>
  )
}
