import { useState } from 'react'

export const useSearch = initialValues => {
  const [filter, setFilter] = useState(initialValues)

  return [
    filter,
    setFilter
  ]
}
