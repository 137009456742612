import React, { useState } from 'react'
import { Slider, FormControl, Typography } from '@material-ui/core'
import '../searchFacets.scss'

/**
 *
 * @param {function} onChange - Function callback for changing slider value
 * @param {string} title - String containing the name of this slider facet - displayed in <Typography> over slider element
 * @param {string} defaultOverride - default value for minMax, stored as current value of filter in Search page
 *
 * @return {Object} React component representing a Slider element for min and max group size
 */
export default function SliderFacet ({ onChange, title, defaultOverride }) {
  const max = 100
  const min = 0

  if (defaultOverride === null || defaultOverride === undefined) {
    defaultOverride = [min, max]
  }

  // querystring doesn't parse to number by default
  defaultOverride = defaultOverride.map(Number)

  const [minMax, setMinMax] = useState(defaultOverride)
  const handleChange = (event, newValue) => {
    setMinMax(newValue)
    onChange(minMax)
  }

  return (
    <FormControl
      className='search-facet-wrapper'
    >
      <Typography>
        {title}
      </Typography>
      <Slider
        className='sliderFacet'
        value={minMax}
        onChange={handleChange}
        valueLabelDisplay='auto'
        aria-labelledby='range-slider'
        getAriaValueText={valuetext}
      />
    </FormControl>
  )
}

function valuetext (value) {
  return `${value}`
}
