
export const externalServiceProviderQuery = (id) => (`
{
    nodeById (id: "${id}"){
        ...ExternalServiceProvider
    }  
}

    fragment TaxonomyTerm on TaxonomyTerm {
    tid: entityId
    uuid: entityUuid
    title: entityLabel
    url: entityUrl {
        path
    }
}

    fragment QualityAssetStarRating on NodeQualityAssetStarRating {
    nid: entityId
    uuid: entityUuid
    title: entityLabel
    url: entityUrl {
        path
    }
}

    fragment ExternalServiceProvider on NodeExternalServiceProvider {
    aim_id: fieldAimId
    nid: nid
    entity_label: entityLabel
    fieldAuditLocationName {
        entity {
        ...TaxonomyTerm
        }
    }
    body: body {
        value
    }
    business_contact: fieldBusinessContact {
        entity {
        ...TaxonomyTerm
        }
    }
    cl_assignment: fieldClAssignment {
        entity {
        ...TaxonomyTerm
        }
    }
    cl_rationale: fieldClRationale {
        value
    }
    company_logo: fieldImage {
        url
    }
    company_website: fieldCompanyWebSite {
        title
    }
    esp_category: fieldEspCategory {
        entity {
        ...TaxonomyTerm
        }
    }
    esp_commodity: fieldEspCommodity {
        entity {
        ...TaxonomyTerm
        }
    }
    esp_type: fieldEspType {
        entity {
        ...TaxonomyTerm
        }
    }
    feeds_item: feedsItem {
        targetId
    }
    group_association: fieldGroup
    location: fieldLocation {
        organization
        addressLine1
        addressLine2
        postalCode
        countryCode
    }
    natural_key: fieldNaturalKey
    new_group_association: fieldNewGroupAssociation
    on_asl: fieldOnAsl
    quality_contact: fieldQualityContact {
        entity {
        ...TaxonomyTerm
        }
    }
    region: fieldRegion {
        entity {
        ...TaxonomyTerm
        }
    }
    fieldStarRating
    star_rating_model: fieldStarRatingModel {
        entity {
        ...QualityAssetStarRating
        }
    }
    supplier_id: fieldSupplierId
     
}`)
