import React, { useContext } from 'react'
import '../styles/menuComponents.scss'
import NavigationCard from './NavigationCard/index.js'
import LinkColumn from './LinkColumn'
import ourGroups from '../images/our_groups_navcard.jpg'
import production from '../images/production.jpg'
import { DataContext } from './context/DataContext'

function MenuContainer (props) {
  const { menu: data } = useContext(DataContext)
  const items = data && data.map(column => {
    return [{
      category: column.label,
      categoryLink: column.url.path,
      links: column.level2.map(({ label: text, url: { path: link } }) => ({ text, link }))
    }]
  })

  function MenuNavPanel (props) {
    return (
      <div
        className={(props.thisPage === props.activePage) ? 'menuNavPanel menuNavPanelActive' : 'menuNavPanel'}
        onClick={() => { props.setPage(props.thisPage) }}
      >
        <span>{props.title}</span>
      </div>
    )
  }

  function MenuNavContainer (props) {
    return (
      <div className='menuNav'>
        <MenuNavPanel activePage={props.page} thisPage='focus' setPage={props.setPage} title='Our Focus' />
        <MenuNavPanel activePage={props.page} thisPage='groups' setPage={props.setPage} title='Our Groups' />
        <MenuNavPanel activePage={props.page} thisPage='capabilities' setPage={props.setPage} title='Our Capabilities' />
      </div>
    )
  }

  return (
    <div className='menu-dropdown'>
      <MenuNavContainer page={props.page} setPage={props.setPage} />
      <div className={`menuContentArea ${props.page === 'focus' ? '' : 'menu-content__hide'}`}>
        <LinkColumn items={items} />
      </div>
      <div className={`menuContentArea ${props.page === 'groups' ? '' : 'menu-content__hide'}`}>
        <NavigationCard
          title='Group Search'
          description="Use faceted search to find the groups you're looking for."
          cardImage={ourGroups}
          redirectLink='?entityType=group'
        />
      </div>
      <div className={`menuContentArea ${props.page === 'capabilities' ? '' : 'menu-content__hide'}`}>
        <NavigationCard
          title='Capabilities Search'
          description='Find processes relevant to you, add them to your cart and send them to your groups.'
          cardImage={production}
          redirectLink='?entityType=process'
        />
      </div>
    </div>
  )
}

export { MenuContainer }
