export const constants = {
  sipoc: {
    suppliers: 0,
    inputs: 1,
    processes: 2,
    outputs: 3,
    customers: 4,
    get allCases () {
      return [this.suppliers, this.inputs, this.processes, this.outputs, this.customers]
    }
  },
  filter: {
    all: 'All',
    none: 'N/A',
    get allCases () {
      return [this.all, this.none]
    }
  }
}
