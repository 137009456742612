export const menuQuery = `
  query {
    menuByName(name: "i") {
      description
      level0: links {
        label
        url {
          path
        }
        level1: links {
          label
          url {
            path
          }
          level2: links {
            label
            url {
              path
            }
          }
        }
      }
    }
  }
`
