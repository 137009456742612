import React from 'react'
import './styles/App.css'
import './styles/navbarComponents.css'
import { HashRouter } from 'react-router-dom'
import Routes from './components/routes'
import HamburgerMenu from './components/HamburgerMenu'
import { ShoppingCartProvider } from './contexts'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { UserProvider } from './components/context/UserContext'
import { DataProvider } from './components/context/DataContext'

const theme = createMuiTheme({
  transitions: {
    create: () => 'none'
  }
})

export default function App () {
  return (
    <UserProvider>
      <DataProvider>
        <ThemeProvider theme={theme}>
          <HashRouter>
            <HamburgerMenu />
            <ShoppingCartProvider>
              <Routes />
            </ShoppingCartProvider>
          </HashRouter>
        </ThemeProvider>
      </DataProvider>
    </UserProvider>
  )
}
