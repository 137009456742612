import React from 'react'
import { Card, CardMedia, CardContent, CardActionArea, Typography, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    margin: 'auto',
    marginTop: 14
  },
  media: {
    height: 160
  }
})

export default function NavigationCard ({ title, description, redirectLink, cardImage }) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Card className={classes.root} id='navCard'>
      <CardActionArea onClick={() => {
        const redirectURL = `/search${redirectLink}`
        history.push(redirectURL)
      }}
      >
        {
          cardImage &&
            <CardMedia
              component='img'
              alt='navigational card image'
              image={cardImage}
              className={classes.media}
            />
        }
        <CardContent>
          <Typography gutterBottom variant='h5' component='h3'>
            {title}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
