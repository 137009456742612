import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { MenuContainer } from '../menuComponents'
import { Toolbar, AppBar, IconButton, InputBase, makeStyles, fade, Drawer, Button } from '@material-ui/core'
import { Menu, Search, Home, Close } from '@material-ui/icons'
import { UserContext } from '../context/UserContext'

import './header.scss'

const useStyles = makeStyles((theme) => ({
  appbarBackground: {
    backgroundColor: 'rgba(0,65,123,1)',
    transition: 'none'
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1rem + ${theme.spacing(4)}px)`,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  },
  logoutButton: {
    color: 'white',
    marginLeft: 'auto',
    fontSize: '.75rem'
  }
}))

export default function Header () {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const [page, setPage] = useState('focus')
  const [isVisible, setIsVisible] = useState(false)
  const { performLogout } = useContext(UserContext)

  useEffect(() => {
    setIsVisible(false)
  }, [location])

  return (
    <>
      <AppBar position='static' className={classes.appbarBackground}>
        <Toolbar>
          <IconButton edge='start' aria-label='menu' onClick={() => setIsVisible(!isVisible)} id='toggleMenuButton'>
            {isVisible ? <Close style={{ color: 'white' }} /> : <Menu style={{ color: 'white' }} />}
          </IconButton>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <Search />
            </div>
            <form
              onSubmit={event => {
                event.preventDefault()
                history.push('/search')
              }}
            >
              <InputBase
                placeholder='Search'
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </form>
          </div>
          <IconButton
            aria-label='home' onClick={() => {
              setIsVisible(false)
              history.push('/')
            }}
          >
            <Home style={{ color: 'white' }} />
          </IconButton>
          <Button
            className={classes.logoutButton}
            onClick={performLogout}
          >
            log out
          </Button>
        </Toolbar>
      </AppBar>
      <div className='header-wrapper'>
        <Drawer
          anchor='top'
          open={isVisible}
          variant='temporary'
          className='header-wrapper'
          disableAutoFocus
          disableBackdropClick
          disableEnforceFocus
          disableRestoreFocus
        >
          <MenuContainer page={page} setPage={setPage} />
        </Drawer>
      </div>
    </>
  )
}
