import React, { useState, useEffect, useContext } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import Home from './Home'
import ContinueToCart from './ContinueToCart'
import ShoppingCart from './ShoppingCart'
import Search from './Search'
import { UserContext } from './context/UserContext'
import GroupDetails from './GroupDetails'
import ScrollToTop from './ScrollToTop'
import ContentDetails from './ContentDetails'

export default function Routes () {
  const currentPath = useLocation()
  const { isAuthenticated } = useContext(UserContext)

  const [shouldShowContinueToCart, setShouldShowContinueToCart] = useState(false)

  const continueToCartExclusionPaths = [
    '/shopping-cart'
  ]

  useEffect(() => {
    setShouldShowContinueToCart(!continueToCartExclusionPaths.includes(currentPath.pathname))
  }, [continueToCartExclusionPaths, currentPath])

  return (
    <>
      {isAuthenticated
        ? (
          <>
            <ScrollToTop />
            <Switch>
              <Route path='/shopping-cart' component={ShoppingCart} />
              <Route exact path='/' component={Home} />
              <Route path='/search' component={Search} />
              <Route path='/group' component={GroupDetails} />
              <Route path='/content/:type/:id' component={ContentDetails} />
            </Switch>
            <Route render={() => shouldShowContinueToCart ? <ContinueToCart /> : null} />
          </>
        ) : null}
    </>
  )
}
