export const capasQuery = () => (`
  {
    nodeQuery (
      filter: {
        conjunction: AND,
        conditions: [
        {field: "type", value: ["capas"]}
        ]
    },
    sort: [
      {field: "changed", direction: DESC}
    ]
    ) {
      count
      entities {
      ...CAPAs
      }
    }  
  }

  fragment TaxonomyTerm on TaxonomyTerm {
    tid: entityId
    uuid: entityUuid
    title: entityLabel
    url: entityUrl {
      path
    }
  }
    
  fragment ClinicalSiteResearchActivity on NodeClinicalResearchSiteActivity {
    nid: entityId
    uuid: entityUuid
    title: entityLabel
    url: entityUrl {
      path
    }
  }

  fragment DiscoveryLocation on NodeDiscoveryLocation {
    nid: entityId
    uuid: entityUuid
    title: entityLabel
    url: entityUrl {
      path
    }
  }

  fragment ExternalServiceProvider on NodeExternalServiceProvider {
    nid: entityId
    uuid: entityUuid
    title: entityLabel
    url: entityUrl {
      path
    }
  }

  fragment FindingID on NodeFindings {
    nid: entityId
    uuid: entityUuid
    title: entityLabel
    url: entityUrl {
      path
    }
  }

  fragment LocalOperatingCompany on NodeLocalOperatingCompany {
    nid: entityId
    uuid: entityUuid
    title: entityLabel
    url: entityUrl {
      path
    }
  }

  fragment QualityInvestigations on NodeQualityInvestigations {
    nid: entityId
    uuid: entityUuid
    title: entityLabel
    url: entityUrl {
      path
    }
  }
  
  fragment CAPAs on NodeCapas {
    nid: entityId
    capaExtensions: fieldCapaExtensions
    capaActionType: fieldCapaActionType {
      entity {
        ...TaxonomyTerm
      }
    }
    capaApprovedBy: fieldCapaApprovedBy {
      entity {
        ...TaxonomyTerm
      }
    }
    capaCancellationSummary: fieldCapaCancellationSummary {
      value
      format
      processed
    }
    capaClosureSummary: fieldCapaClosureSummary {
      value
      format
      processed
    }
    capaExecutionSummary: fieldCapaExecutionSummary {
      value
      format
      processed
    }
    capaExtDueDateStatus: fieldCapaExtDueDateStatus {
      entity{
        ...TaxonomyTerm
      }
    }
    capaInterimControlDetail: fieldCapaInterimControlDetai {
      value
      format
      processed
    }
    capaInterimUpdateSummary: fieldCapaInterimUpdateSummar {
      value
      format
      processed
    }
    capaOriginalDueDate: fieldCapaOriginalDueDate {
      value
      date
    }
    capaOwningBusinessUnit: fieldCapaOwningBusinessUnit {
      entity {
        ...TaxonomyTerm
      }
    }
    capaOwningSegment: fieldCapaOwningSegment {
      entity {
        ...TaxonomyTerm
      }
    }
    capaOwningSiteRegion: fieldCapaOwningSiteRegion {
      entity {
        ...TaxonomyTerm
      }
    }
    capaRevisedEcPlanApproved: fieldCapaRevisedEcPlanAppro {
      entity {
        ...TaxonomyTerm
      }
    }
    capaRootCauses: fieldCapaRootCauseS {
      entity {
        ...TaxonomyTerm
      }
    }
    capaSourceReferenceNumber: fieldCapaSourceReferenceNumb {
        entity {
        ...TaxonomyTerm
      }
    }
    title
    capaUpdateApprovalDate: fieldCapaUpdateApprovalDate {
        value
        date
    }
    capaUpdateApprovedBy: fieldCapaUpdateApprovedBy {
        entity {
        ...TaxonomyTerm
      }
    }
    clinicalResearchSite: reverseFieldClinicalResearchSiteActNode{
      entities {
        ...ClinicalSiteResearchActivity
      }
    }
    discoveryLocation: reverseFieldDiscoveryLocationNode {
      entities {
        ...DiscoveryLocation
      }
    }
    externalServiceProvider: reverseFieldExternalServicePrNode {
      entities {
        ...ExternalServiceProvider
      }
    }
    findingId: fieldFindingId {
      entity {
        ...FindingID
      }
    }
    groupAssociation: fieldGroup 
    localOpCo: reverseFieldLocalOpCoNode {
      entities {
        ...LocalOperatingCompany
      }
    }
    naturalKey: fieldNaturalKey 
    newGroupAssociation: fieldNewGroupAssociation
    qiId: fieldQiId {
      entity {
        ...QualityInvestigations
      }
    }
    therapeuticArea: fieldTherapeuticArea {
      entity {
        ...TaxonomyTerm
      }
    }
}`)
