import React, { Fragment } from 'react'
import { Typography } from '@material-ui/core'

import PropTypes from 'prop-types'

import './contentHome.scss'
import { Mail } from '@material-ui/icons'

export const ContentHome = ({ contacts, location, ratingModel, alternativeNames }) => {
  return (
    <div className='content-home'>
      <div>
        <img className='header--image' alt=''/>
      </div>
      <div className='content-company-name'>
        <Typography variant='h4'>
          Company Name
        </Typography>
      </div>
      <div className='content-home-wrapper'>
        <div>
          <div className='content-home-info__space-apart'>
            <Typography variant='h5'>
              Contacts
            </Typography>
            {Object.entries(contacts).map(([contactType, contact]) => (
              <Fragment key={contactType + contact.name}>
                <Typography>
                  {contactType}:
                </Typography>
                <Typography>
                  <a href={`mailto:${contact.email}`} className='content-mailto-link'>
                    {contact.name} <Mail className='mail-icon__shift-left' />
                  </a>
                </Typography>
              </Fragment>
            ))}
          </div>
          <div className='content-home-info__space-apart'>
            <Typography variant='h5'>
              Location
            </Typography>
            <Typography>
              {location.name}
            </Typography>
            <Typography>
              {location.address}
            </Typography>
            <Typography>
              {location.country}
            </Typography>
          </div>

          <div className='content-home-info__space-apart'>
            <Typography variant='h5'>
              Star Rating Model
            </Typography>
            <Typography>
              {ratingModel}
            </Typography>
          </div>
        </div>
        <div className='content-home--right-column'>
          <Typography variant='h5'>
            Alternative Names
          </Typography>
          <ul className='alternative-names__remove-style-type'>
            {alternativeNames.map(name => (<li key={name}><Typography>{name}</Typography></li>))}
          </ul>
        </div>
      </div>
    </div>

  )
}

ContentHome.propTypes = {
  contacts: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  ratingModel: PropTypes.string.isRequired,
  alternativeNames: PropTypes.array.isRequired
}
