import React, { useState, useCallback } from 'react'
import _ from 'lodash'

import './ContentSearchBar.scss'

import { Save, Print, Description } from '@material-ui/icons'

export const ContentSearchBar = ({ setFilter }) => {
  const [query, setQuery] = useState('')
  const delayedQuery = useCallback(_.debounce(q => sendQuery(q), 500), []
  )
  const sendQuery = (q) => {
    setFilter(q)
  }
  const typeAhead = value => {
    setQuery(value)
    delayedQuery(value)
  }

  return (
    <div className='toolBarContainer'>
      <div className='searchContainer'>
        <form>
          <input
            type='text'
            placeholder='Search'
            value={query}
            onChange={event => {
              typeAhead(event.target.value)
            }}
          />
        </form>
      </div>
      <div className='iconContainer'>
        <Save className='icon' />
      </div>
      <div className='iconContainer'>
        <Print className='icon' />
      </div>
      <div className='iconContainer'>
        <Description className='icon' />
      </div>
    </div>
  )
}
