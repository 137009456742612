import React, { useEffect, useState } from 'react'
import { capasQuery } from '../../../queries'
import './CAPAs.scss'
import SelectFacet from '../../SearchFacets/SelectFacet'
import { useHistory } from 'react-router-dom'
import SideNavContentTypes from './SideNavigation/SideNavContentTypes/SideNavContentTypes'
import SideNavGroups from './SideNavigation/SideNavGroups/SideNavGroups'
import ResultPreview from './Results/ResultPreview/ResultPreview'
import { KeyboardBackspace } from '@material-ui/icons'
import { Typography } from '@material-ui/core'
import { constants } from '../../../lib/constants'
import ResultModal from './Results/ResultModal/ResultModal'
import { ContentSearchBar } from './ContentSearchBar/ContentSearchBar'

export const CAPAs = () => {
  const auditID = 1
  const findingID = 1
  const QIID = 1
  const [filter, setFilter] = useState({
    group: constants.filter.all,
    text: ''
  })
  const history = useHistory()
  const [dataToDisplay, setDataToDisplay] = useState()
  const [allData, setAllData] = useState()
  const [shouldShowModal, setShouldShowModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [hasLoadingError, setHasLoadingError] = useState(false)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_TARGET_URL}/graphql`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({ query: capasQuery() })
    })
      .then(response => response.json())
      .then(response => {
        setAllData(response?.data?.nodeQuery?.entities)
        setDataToDisplay(response?.data?.nodeQuery?.entities)
      })
      .catch(() => setHasLoadingError(true))
      .finally(() => setIsLoading(false))

    // Filter for groups.
    switch (filter.group) {
      case constants.filter.all:
        setDataToDisplay(allData)
        break
      case constants.filter.none:
        setDataToDisplay(allData.filter(item => !item.group))
        break
      default:
        setDataToDisplay(allData.filter(item => item.group === filter.group))
        break
    }
    // Filter for text.
    if (filter.text.length > 0) {
      setDataToDisplay(data =>
        (data.filter(item =>
          JSON.stringify(item).toLowerCase().indexOf(filter.text.toLowerCase()) > -1)))
    }
  }, [allData, filter])

  const filterType1 = [
    constants.filter.all,
    constants.filter.none,
    ...(new Set(allData?.reduce((acc, entity) => {
      if (entity.group) {
        acc.push(entity.group)
      }
      return acc
    }, [])))
  ]

  const filterData = category => value => {
    setFilter(data => ({
      ...data,
      [category]: value
    }))
  }

  const createPages = (item) => {
    const pages = [
      {
        topic: 'Base Information',
        fields: [
          `Group: ${item?.group}`,
          `Finding ID: ${item.findingID?.entity.nid}`,
          `QI ID: ${item.qiID?.entity.nid}`,
          `Group: ${item?.groupAssociation}`,
          `Source Reference Number: ${item.capaSourceReferenceNumber?.entity.title}`,
          `Therapeutic Area ${item.therapeuticArea?.entity.title}`,
          `Original Due Date: ${item.capaOriginalDueDate?.value}`,
          `Approved By: ${item.capaApprovedBy?.entity.title}`,
          `Action Type: ${item.capaActionType?.entity.title}`,
          `Root Causes: ${item.capaRootCauses?.map((i) => { return ` ${i.entity.title}` })}`
        ]
      },
      {
        topic: 'Extension Information',
        fields: [
          `Number of Extensions: ${item?.capaExtensions}`,
          'Extension Due Date Status: '
        ]
      },
      {
        topic: 'Interim Information',
        fields: [
          `Control Details: ${item.capaInterimControlDetail?.value}`,
          `Control Update Summary: ${item.capaInterimUpdateSummary?.value}`
        ]
      },
      {
        topic: 'Update Information',
        fields: [
          `Approval Date: ${item.capaUpdateApprovalDate?.value}`,
          `Approved By: ${item.capaApprovedBy?.entity.title}`
        ]
      },
      {
        topic: 'Owning Information',
        fields: [
          `Business Unit: ${item.capaOwningBusinessUnit?.entity.value}`,
          `Segment: ${item.capaOwningSegment?.entity.value}`,
          `Site Region: ${item.capaOwningSiteRegion?.entity.title} `
        ]
      },
      {
        topic: 'Summaries',
        fields: [
          `Cancellation: ${item.capaCancellationSummary?.value}`,
          `Closure: ${item.capaClosureSummary?.value}`,
          `Execution: ${item.capaExecutionSummary?.value}`
        ]
      }
    ]
    return pages
  }

  const createPreviews = (data) => {
    return data && data.map((item, i) =>
      <ResultPreview
        key={i}
        title={item.title}
        nid={item.nid}
        naturalKey={item.naturalKey}
        group={item.group}
        approvedBy={item.qiApprovedBy?.entity.title}
        openModalCallback={() => {
          setSelectedItem(item)
          setShouldShowModal(true)
        }}
      />)
  }

  if (hasLoadingError) {
    return (
      <div className='quality-investigations-wrapper'>
        <div className='header-wrapper'>
          <div className='back-button__clickable' onClick={() => { history.goBack() }}>
            <KeyboardBackspace /> <Typography>Back</Typography>
          </div>
        </div>
        No data was found for this CAPA.
      </div>
    )
  }

  return !isLoading && allData && (
    <div className='content-capa-wrapper'>
      <div className='header-wrapper'>
        <div className='back-button__clickable' onClick={() => { history.goBack() }}>
          <KeyboardBackspace /> <Typography>Back</Typography>
        </div>
        <div className='content-title'>
          <Typography variant='h3'>
            CAPAs
          </Typography>
        </div>
      </div>
      <div className='search-bar-wrapper'>
        <ContentSearchBar
          setFilter={value => setFilter(data => ({
            ...data,
            text: value
          }))}
        />
      </div>
      <div className='result-wrapper'>
        <div className='filter-wrapper--left-column'>
          <SelectFacet
            onChange={(value) => {
              filterData('group')(value)
            }}
            selectOptions={filterType1}
            title='Group'
          />
        </div>
        <div className='table-wrapper'>
          {createPreviews(dataToDisplay)}
        </div>
        <div className='sidebar-wrapper--right-column'>
          <SideNavContentTypes
            contentType='Audit'
            number={1}
            link={`/content/audits/${auditID}`}
          />
          <SideNavContentTypes
            contentType='Findings'
            number={5}
            link={`/content/qis/${findingID}`}
          />
          <SideNavContentTypes
            contentType='Quality Investigations'
            number={5}
            link={`/content/capas/${QIID}`}
          />
          <SideNavGroups />
        </div>
      </div>
      {Object.keys(selectedItem).length > 0 &&
        <ResultModal
          isOpen={shouldShowModal}
          onClose={() => setShouldShowModal(false)}
          nid={selectedItem.nid}
          naturalKey={selectedItem.naturalKey}
          title={selectedItem.title}
          pages={createPages(selectedItem)}
        />}
    </div>
  )
}
