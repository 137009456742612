import React, { useEffect, useState } from 'react'
import { externalServiceProviderQuery } from '../../../queries'
import { useHistory } from 'react-router-dom'
import './externalServiceProvider.scss'
import { Typography } from '@material-ui/core'
import { KeyboardBackspace, Mail } from '@material-ui/icons'

export const ExternalServiceProvider = ({ id }) => {
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [hasLoadingError, setHasLoadingError] = useState(false)
  const history = useHistory()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_TARGET_URL}/graphql`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({ query: externalServiceProviderQuery(id) })
    })
      .then(response => response.json())
      .then(response => setData(response))
      .catch(() => setHasLoadingError(true))
      .finally(() => setIsLoading(false))
  }, [id])

  if (hasLoadingError) {
    return (
      <div className='content-home'>
        <div className='header-wrapper'>
          <div className='back-button__clickable' onClick={() => { history.goBack() }}>
            <KeyboardBackspace /> <Typography>Back</Typography>
          </div>
        </div>
        No data was found for this ESP.
      </div>
    )
  }
  return !isLoading && data && (
    <div className='content-home'>
      <div>
        <img className='header--image' src={data.company_logo?.url} alt='company-logo' />
      </div>
      <div className='content-company-name'>
        <Typography variant='h4' />
      </div>
      <div className='content-home-wrapper'>
        <div>
          <div className='content-home-info__space-apart'>
            <Typography variant='h5'>
                Contacts
            </Typography>
            <Typography>
              Business Contact:
            </Typography>
            <Typography>
              <a href={`mailto:${data.business_contact?.entity.email}`} className='content-mailto-link'>
                {data.business_contact?.entity.title} <Mail className='mail-icon__shift-left' />
              </a>
            </Typography>
            <Typography>
              Quality Contact:
            </Typography>
            <Typography>
              <a href={`mailto:${data.quality_contact?.entity.email}`} className='content-mailto-link'>
                {data.quality_contact?.entity.title} <Mail className='mail-icon__shift-left' />
              </a>
            </Typography>
          </div>
          <div className='content-home-info__space-apart'>
            <Typography variant='h5'>
                Locations:
            </Typography>
            {data.location?.map((i, key) => {
              return ([
                <Typography key={key}>
                  {i?.organization}
                </Typography>,
                <Typography key={key}>
                  {i?.addressLine1}
                </Typography>,
                <Typography key={key}>
                  {i?.addressLine2}
                </Typography>,
                <Typography key={key}>
                  {i?.country}
                </Typography>,
                <Typography key={key}>
                  {i?.postalCode}
                </Typography>
              ])
            })}
            <Typography>
              {data.location?.country}
            </Typography>
          </div>
          <div className='content-home-info__space-apart'>
            <Typography variant='h5'>
                Star Rating Model
            </Typography>
            <Typography>
              {data.star_rating_model?.entity.title}
            </Typography>
          </div>
        </div>
        <div className='content-home--right-column'>
          <Typography variant='h5'>
              Associated Companies
          </Typography>
          <ul className='alternative-names__remove-style-type'>
            {data.fieldAuditLocationName?.map((i) => { return i.entity.title })}
          </ul>
        </div>
      </div>
    </div>
  )
}
