import React from 'react'
import AuditItem from '../AuditItem'
import { Paper, Typography, Grid, Box } from '@material-ui/core'
import microscope from '../../images/microscope.jpg'
import doctor from '../../images/doctor.jpg'

import './pendingItems.scss'

export default function PendingItems () {
  const items = [
    {
      id: 1,
      title: 'Clinical Site 1',
      cardImage: microscope,
      rating: 3,
      recipients: [
        {
          name: 'Audit Prioritization Team',
          email: 'carol@email.com'
        },
        {
          name: 'Oncology Strategy Lead',
          email: 'Steve@email.com'
        }

      ]
    },
    {
      id: 2,
      title: 'Clinical Site 2',
      cardImage: doctor,
      rating: 5,
      recipients: [
        {
          name: 'Audit Prioritization Team',
          email: 'person@email.com'
        },
        {
          name: 'Oncology Strategy Lead',
          email: 'name@email.com'
        }

      ]
    }
  ]

  return (
    <div className='pending-items-wrapper'>
      <Paper>
        <Box p={3}>
          <Typography variant='h4' component='h1'>
              New Items:
          </Typography>
        </Box>
        <Grid container>
          {items.map((item, idx) => (
            <Box p={2} key={`pending-item$-${idx}`}>
              <AuditItem item={item} />
            </Box>
          ))}
        </Grid>
      </Paper>
    </div>
  )
}
