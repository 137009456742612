import React from 'react'
import { Container } from '@material-ui/core'
import * as d3 from 'd3'
import { sankey, sankeyLinkHorizontal } from 'd3-sankey'
import chroma from 'chroma-js'

export const Sankey = ({ data }) => {
  const margin = { top: 10, right: 10, bottom: 10, left: 10 }
  const width = 450 - margin.left - margin.right
  const height = 480 - margin.top - margin.bottom

  const { nodes, links } = sankey()
    .nodeWidth(15)
    .nodePadding(10)
    .extent([[1, 1], [width - 1, height - 5]])(data)
  const color = chroma.scale('Set3').classes(nodes.length)
  const colorScale = d3
    .scaleLinear()
    .domain([0, nodes.length])
    .range([0, 1])

  const SankeyNode = ({ name, x0, x1, y0, y1, color }) => (
    <>
      <rect x={x0} y={y0} width={x1 - x0} height={y1 - y0} fill={color}>
        <title>{name}</title>
      </rect>
      <text x={x0} y={y0} transform='translate(0,20)' style={{ fontSize: '12px' }}>
        {name}
      </text>
    </>
  )

  const SankeyLink = ({ link, color }) => (
    <path
      d={sankeyLinkHorizontal()(link)}
      style={{
        fill: 'none',
        strokeOpacity: '.3',
        stroke: color,
        strokeWidth: Math.max(1, link.width)
      }}
    />
  )

  return (
    <Container>
      <svg height='100vh' width='100vh'>
        <g style={{ mixBlendMode: 'multiply' }}>
          {nodes.map((node, i) => (
            <SankeyNode
              {...node}
              color={color(colorScale(i)).hex()}
              key={node.name}
            />
          ))}
          {links.map((link, i) => (
            <SankeyLink
              link={link}
              color={color(colorScale(link.source.index)).hex()}
              key={i}
            />
          ))}
        </g>
      </svg>
    </Container>
  )
}
