import React, { useContext } from 'react'
import { Card, CardMedia, CardContent, makeStyles, IconButton, CardHeader } from '@material-ui/core'
import { ShoppingCart, CheckOutlined } from '@material-ui/icons'
import StarRating from '../StarRating'
import { ShoppingCartContext } from '../../contexts'
import { useHistory } from 'react-router-dom'

import './auditItem.scss'

const useStyles = makeStyles({
  root: {
    minWidth: 200,
    maxWidth: 300
  }
})

export default function AuditItem ({ item }) {
  const { addItemToCart, removeItem, shoppingCartItems } = useContext(ShoppingCartContext)
  const history = useHistory()
  const classes = useStyles()

  const toggleItemInCart = clickedItem => {
    if (shoppingCartItems && shoppingCartItems.has(clickedItem.id)) {
      removeItem(clickedItem.id)
    } else {
      addItemToCart(clickedItem)
    }
  }

  return (
    <Card elevation={4} className={classes.root}>
      <CardHeader
        action={
          <IconButton aria-label='add to cart' component='span' onClick={() => toggleItemInCart(item)}>
            {shoppingCartItems && shoppingCartItems.has(item.id) ? <CheckOutlined /> : <ShoppingCart />}
          </IconButton>
        }
        title={item.title}
      />
      {
        item.cardImage &&
          <CardMedia
            className='card-img__resize'
            component='img'
            alt='audit item'
            image={item.cardImage}
            onClick={() => {
              history.push('/content/qis/48156')
            }}
          />
      }
      <CardContent>
        <StarRating rating={item.rating} />
      </CardContent>
    </Card>
  )
}
