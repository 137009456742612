import React from 'react'
import { Paper, IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import './SendAllMessage.scss'

/**
 *
 * @param {bool} isVisible - Boolean representing the visibility of the popup
 * @param {function} setVisibility - function callback for changing the value of isVisible once message is acknowledged
 * @param {number} shoppingCartSize - value representing the number of items in the shopping cart when "Send All" was pressed
 * @param {function} setShoppingCartSize - function callback for setting shoppingCartSize when message is acknowledged
 *
 * @return {Object} The React wrapper compoenent for the popup
 */
export default function SendAllMessage ({ isVisible, setVisibility, shoppingCartSize, setShoppingCartSize }) {
  const handleClick = () => {
    setVisibility(false)
    setShoppingCartSize(0)
  }

  return (
    isVisible ? <MessageBody shoppingCartSize={shoppingCartSize} handleClick={handleClick} /> : null
  )
}

/**
 * This function creates a UI element for when a user sends a site to the next user in the SIPOC chain
 *
 * @param {number} shoppingCartSize - value representing the number of items in the shopping cart when "Send All" was pressed
 * @param {function} handleClick - The callback function for after the user has acknowledged the popup
 *
 * @return {Object} The React elemnt for the email sent popup
 */
function MessageBody ({ handleClick, shoppingCartSize }) {
  return (
    <Paper
      className='popup'
      elevation={5}
    >
      <IconButton
        onClick={handleClick}
        className='popup-close-btn'
      >
        <CloseIcon />
      </IconButton>
      <Typography variant='h5' className='popup-title'>Message Sent</Typography>
      <Typography variant='body1' className='popup-body'>
        You have sent {`${shoppingCartSize} item${shoppingCartSize > 1 ? 's' : ''}. `}
        The recipients of {`${shoppingCartSize > 1 ? 'these items' : 'this item'} `} will be notified by email shortly.
      </Typography>
    </Paper>
  )
}
