import React from 'react'
import { Container } from '@material-ui/core'
import { Sankey } from '../Graphs'

export const Inputs = () => {
  const data = {
    nodes: [
      { node: 0, name: 'Operating Model Pillar' },
      { node: 1, name: 'Data Excellence Pillar' },
      { node: 2, name: 'Risk Based Knowledge Platform' },
      { node: 3, name: 'IT Platform Engineering Activities' },
      { node: 4, name: 'Drupal Data Ingestion' },
      { node: 5, name: 'Drupal View Capabilities' },
      { node: 6, name: 'Webforms - Capturing Data in Context' },
      { node: 7, name: 'Drupal Shopping Cart Experience' },
      { node: 8, name: 'User Experience of the Site' }
    ],
    links: [
      { source: 0, target: 2, value: 3 },
      { source: 1, target: 2, value: 3 },
      { source: 2, target: 3, value: 1 },
      { source: 2, target: 4, value: 1 },
      { source: 2, target: 5, value: 1 },
      { source: 2, target: 6, value: 1 },
      { source: 2, target: 7, value: 1 },
      { source: 2, target: 8, value: 1 }
    ]
  }

  return (
    <Container>
      <Sankey data={data} />
    </Container>
  )
}
