import React, { useState } from 'react'
import { Typography, Tabs, Tab, Button, Container } from '@material-ui/core'

import headerImage from '../../images/our_groups_navcard.jpg'

import './detailsTemplate.scss'

import PropTypes from 'prop-types'

/**
 * Generates a view generalized to display any content that is pulled from the CIA Drupal site.
 *
 * @param {Object} ModalForContent The view that appears when modal presentation is requested by the Component.
 * @param {Object} tabContents A set of Key-Value pairs, where each Key is the name of a tab and the value is the view
 *                             that appears when the tab is selected.
 *
 * @returns {Object} The view containing content details.
 */
const DetailsTemplate = ({ contentData, ModalForContent, tabContents }) => {
  const [currentTab, setCurrentTab] = useState(0)

  return (
    <>
      <div
        className='details-wrapper'
      >
        <div className='header--image' />
        <Container>
          <div className='details-title'>
            <div className='logo-title__align'>
              <img src={headerImage} alt='' />
              <Typography variant='h4'>
                {contentData.name}
              </Typography>
            </div>
            <Button>
              Manage
            </Button>
          </div>
          <div>
            <Tabs variant='fullWidth' value={currentTab} onChange={(_, newVal) => setCurrentTab(newVal)}>
              {Object.keys(tabContents).map(tabName => <Tab label={tabName} key={tabName} />)}
            </Tabs>
            <div>
              {Object.values(tabContents)[currentTab]()}
            </div>
          </div>
        </Container>
        {ModalForContent()}
      </div>
    </>
  )
}

export default DetailsTemplate

DetailsTemplate.propTypes = {
  contentData: PropTypes.object.isRequired,
  ModalForContent: PropTypes.func,
  tabContents: PropTypes.object
}
