import React, { useState, useEffect } from 'react'
import { Modal, Typography } from '@material-ui/core'

import { Close, ArrowLeft, ArrowRight } from '@material-ui/icons'
import { Suppliers, Inputs, Processes, Outputs, Customers } from '../SipocDetails'

import PropTypes from 'prop-types'
import './sipocModal.scss'
import { constants } from '../../lib'

const SipocModal = ({ isOpen, onClose, onNavigate, sipocIndex }) => {
  const [modalContents, setModalContents] = useState(<Suppliers />)
  const [modalTitle, setModalTitle] = useState('Suppliers')

  useEffect(() => {
    const GroupForSipocIndex = () => {
      switch (sipocIndex % constants.sipoc.allCases.length) {
        case constants.sipoc.suppliers:
          return ['Suppliers', <Suppliers key={constants.sipoc.suppliers} />]
        case constants.sipoc.inputs:
          return ['Inputs', <Inputs key={constants.sipoc.inputs} />]
        case constants.sipoc.processes:
          return ['Processes', <Processes key={constants.sipoc.processes} />]
        case constants.sipoc.outputs:
          return ['Outputs', <Outputs key={constants.sipoc.outputs} />]
        default:
          return ['Customers', <Customers key={constants.sipoc.customers} />]
      }
    }

    const [title, contents] = GroupForSipocIndex()
    setModalTitle(title)
    setModalContents(contents)
  }, [sipocIndex])

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className='sipoc-modal'
    >
      <div className='sipoc-modal-container__center-contents'>
        <ArrowLeft className='sipoc-navigation-arrow sipoc-navigation-arrow__resize' onClick={() => onNavigate((sipocIndex - 1) % constants.sipoc.allCases.length)} />
        <div className='sipoc-modal-contents'>
          <div className='sipoc-modal-header'>
            <Typography variant='h4'>{modalTitle}</Typography><Close className='modal-close-button' onClick={onClose} />
          </div>
          <div className='sipoc-modal--main-content'>
            {modalContents}
          </div>
        </div>
        <ArrowRight className='sipoc-navigation-arrow sipoc-navigation-arrow__resize' onClick={() => onNavigate((sipocIndex + 1) % constants.sipoc.allCases.length)} />
      </div>
    </Modal>
  )
}

export default SipocModal

SipocModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  sipocIndex: PropTypes.number.isRequired
}
