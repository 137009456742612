import React from 'react'
import { Typography, Container } from '@material-ui/core'

export const Customers = () => {
  return (
    <Container>
      <Typography>Customer Details Coming Soon!</Typography>
    </Container>
  )
}
