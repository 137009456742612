import React from 'react'
import { Typography, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

export default function SideNavContentTypes ({ contentType, number, link }) {
  const history = useHistory()
  return (
    <div className='content-type-item-wrapper'>
      <Typography variant='h6'>
        {contentType}
      </Typography>
      <Button
        className='redirect-button'
        onClick={() => {
          history.push(link)
        }}
      >
        <Typography variant='h5'>{number}</Typography>
      </Button>
    </div>
  )
}
