import React, { createContext, useState } from 'react'

export const ShoppingCartContext = createContext()

export const ShoppingCartProvider = ({ children }) => {
  const [shoppingCartItems, setShoppingCartItems] = useState(new Map())
  const [emailRecipients, setEmailRecipeints] = useState(new Map())

  const addItemToCart = item => {
    setShoppingCartItems(new Map(shoppingCartItems.set(item.id, item)))
  }

  const removeItem = itemId => {
    const clonedShoppingCartItems = new Map(shoppingCartItems)
    clonedShoppingCartItems.delete(itemId)
    setShoppingCartItems(clonedShoppingCartItems)
  }

  const clearCart = _ => {
    setShoppingCartItems(new Map())
  }

  const addEmailRecipient = email => {
    setEmailRecipeints(new Map(emailRecipients.set(email.id, email)))
  }

  const removeRecipient = emailId => {
    const clonedEmailRecipients = new Map(emailRecipients)
    clonedEmailRecipients.delete(emailId)
    setShoppingCartItems(clonedEmailRecipients)
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        addItemToCart,
        removeItem,
        clearCart,
        shoppingCartItems,

        addEmailRecipient,
        removeRecipient,
        emailRecipients
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}
