import React, { createContext, useState, useEffect } from 'react'
import { useTimer } from 'use-timer'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const endTime = 60 * 15
  const ROUTE_TO_VERIFY_ACCESS = `${process.env.REACT_APP_TARGET_URL}/graphql`

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const performLogin = () => {
    if (!isAuthenticated) {
      window.location = `${process.env.REACT_APP_TARGET_URL}/saml_login?redirect=${window.location}`
    }
  }

  const performLogout = () => {
    setIsAuthenticated(false)

    // There's a chance that the user is already logged out.
    // If they are, a call to /user/logout will fail, so we need to send them straight to the logout message.
    callIfAuthenticated(() => {
      window.location = `${process.env.REACT_APP_TARGET_URL}/user/logout`
    }, () => {
      window.location = `${process.env.REACT_APP_TARGET_URL}/logout`
    })()
  }

  /**
   * Applies either the successful or failed callback depending on whether the user is authenticated properly.
   *
   * @param {function} success Callback performed on success.
   * @param {function} failure Callback performed if authentication failed.
   *
   * @returns {function} A function that takes in an array of parameters called when the callback is invoked.
   */
  const callIfAuthenticated = (success, failure) => params => {
    fetch(ROUTE_TO_VERIFY_ACCESS, {
      method: 'GET',
      credentials: 'include',
      cache: 'no-cache'
    })
      .then(result => {
        setIsAuthenticated(result.ok)
        const functionArgs = params || []

        if (result.ok) {
          return success && success.apply(undefined, functionArgs)
        } else {
          return failure && failure.apply(undefined, functionArgs)
        }
      })
      .catch(() => {
        const functionArgs = params || []
        setIsAuthenticated(false)
        failure && failure.apply(undefined, functionArgs)
      })
  }

  const countDownUntilLogout = () => {
    isAuthenticated && start()
  }

  const verifyIsAuthenticated = () => {
    if (!isAuthenticated) {
      callIfAuthenticated(undefined, performLogin)()
    }
  }

  const setRefreshTimers = () => {
    window.onclick = isAuthenticated ? () => {
      reset()
      start()
    } : undefined
    window.onscroll = isAuthenticated ? () => {
      reset()
      start()
    } : undefined
  }

  const { start, reset } = useTimer({
    endTime,
    onTimeOver: performLogout
  })

  useEffect(countDownUntilLogout, [isAuthenticated])
  useEffect(setRefreshTimers, [isAuthenticated])
  useEffect(verifyIsAuthenticated, [isAuthenticated])

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        performLogin,
        performLogout
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
